import clsx from 'clsx';
import React, { ReactNode } from 'react';

export const sharedStyles = `border transition-all inline-flex items-center`;

export const variantStyles = {
  primary: `
    border-transparent shadow-medium-down
    bg-action-fill-primary-enabled text-action-content-primary-enabled
    hover:bg-action-fill-primary-hover hover:text-action-content-primary-hover
    focus:bg-action-fill-primary-active focus:text-action-content-primary-active focus:shadow-focus
    disabled:bg-action-fill-primary-disabled disabled:text-action-content-primary-disabled disabled:shadow-none`,
  secondary: `
    shadow-light-down
    bg-action-fill-secondary-enabled text-action-content-secondary-enabled border-action-outline-secondary-enabled
    hover:bg-action-fill-secondary-hover hover:text-action-content-secondary-hover hover:border-action-outline-secondary-hover
    focus:bg-action-fill-secondary-active focus:text-action-content-secondary-active focus:border-action-outline-secondary-active focus:shadow-focus
    disabled:bg-action-fill-secondary-disabled disabled:text-action-content-secondary-disabled disabled:border-action-outline-secondary-disabled disabled:shadow-none`,
  tertiary: `
  border-transparent
    bg-action-fill-tertiary-enabled text-action-content-tertiary-enabled
    hover:bg-action-fill-tertiary-hover hover:text-action-content-tertiary-hover hover:border-action-outline-tertiary-hover
    focus:bg-action-fill-tertiary-active focus:text-action-content-tertiary-active focus:border-action-outline-tertiary-active
    disabled:bg-action-fill-tertiary-disabled disabled:text-action-content-tertiary-disabled disabled:shadow-none`,
  'negative-primary': `
    border-transparent shadow-light-down
    bg-action-fill-negative-primary-enabled text-action-content-negative-primary-enabled
    hover:bg-action-fill-negative-primary-hover hover:text-action-content-negative-primary-hover
    focus:bg-action-fill-negative-primary-active focus:text-action-content-negative-primary-active focus:shadow-focus
    disabled:bg-action-fill-negative-primary-disabled disabled:text-action-content-negative-primary-disabled disabled:shadow-none`,
  'negative-secondary': `
    shadow-light-down
    bg-action-fill-negative-secondary-enabled text-action-content-negative-secondary-enabled border-action-outline-negative-secondary-enabled
    hover:bg-action-fill-negative-secondary-hover hover:text-action-content-negative-secondary-hover hover:border-action-outline-negative-secondary-hover
    focus:bg-action-fill-negative-secondary-active focus:text-action-content-negative-secondary-active focus:border-action-outline-negative-secondary-active focus:shadow-focus
    disabled:bg-action-fill-negative-secondary-disabled disabled:text-action-content-negative-secondary-disabled disabled:border-action-outline-negative-secondary-disabled disabled:shadow-none`,
  'positive-primary': `
    border-transparent shadow-light-down
    bg-action-fill-positive-primary-enabled text-action-content-positive-primary-enabled
    hover:bg-action-fill-positive-primary-hover hover:text-action-content-positive-primary-hover
    focus:bg-action-fill-positive-primary-active focus:text-action-content-positive-primary-active focus:shadow-focus
    disabled:bg-action-fill-positive-primary-disabled disabled:text-action-content-positive-primary-disabled disabled:shadow-none`,
};

export const sizeStyles = {
  sm: `rounded-md text-button-md font-button-md gap-x-1 px-1 py-0.5 [&_svg]:w-4`,
  md: `rounded-md text-button-md font-button-md gap-x-2 px-2 py-1.5 [&_svg]:w-4`,
  lg: `rounded-lg text-button-lg font-button-lg gap-x-2 px-2.5 pt-2 pb-2.25 [&_svg]:w-5`,
};

type ButtonContentProps = {
  children?: ReactNode;
  IconRight?: ReactNode;
  IconLeft?: ReactNode;
};

export type BaseButtonProps = ButtonContentProps & {
  size?: keyof typeof sizeStyles;
  variant?: keyof typeof variantStyles;
};

export default function ButtonContent({ children, IconLeft, IconRight }: ButtonContentProps) {
  return (
    <>
      {IconLeft && <span className="flex-initial">{IconLeft}</span>}
      {children && (
        <span className={clsx('flex-1', IconRight && 'text-left', IconLeft && 'text-right')}>{children}</span>
      )}
      {IconRight && <span className="flex-initial">{IconRight}</span>}
    </>
  );
}
