import clsx from 'clsx';
import { HTMLAttributes, ReactNode, forwardRef } from 'react';

const iconStatuses = {
  default: `text-content-secondary`,
  primary: `text-content-primary`,
  active: `text-content-informative`,
  onHold: `text-content-tertiary`,
  declined: `text-content-negative`,
  closed: `text-content-positive`,
};

const colorBarValues = {
  default: `var(--color-content-secondary)`,
  primary: `var(--color-content-primary)`,
  active: `var(--color-content-informative)`,
  onHold: `var(--color-content-tertiary)`,
  declined: `var(--color-content-negative)`,
  closed: `var(--color-content-positive)`,
};

type ChipProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  interactive?: boolean;
  IconLeft?: ReactNode;
  IconRight?: ReactNode;
  iconStatus?: keyof typeof iconStatuses;
  colorBar?: keyof typeof colorBarValues | (string & NonNullable<unknown>);
  className?: string;
};

const sharedStyles = `inline-flex flex-nowrap items-center gap-x-1 px-1 py-0.75 rounded-sm border border-action-outline-secondary-enabled max-w-36 text-xs`;
const interactiveStyles = `bg-action-fill-secondary-enabled text-content-primary shadow-medium-down hover:border-action-outline-secondary-hover hover:bg-action-fill-secondary-hover`;
const nonInteractiveStyles = `bg-action-fill-secondary-disabled border-action-outline-secondary-disabled text-content-secondary`;

export default forwardRef<HTMLDivElement, ChipProps>(function Chip(
  {
    children,
    interactive = false,
    IconLeft,
    IconRight,
    iconStatus = 'default',
    colorBar,
    className = '',
    ...props
  }: ChipProps,
  ref,
) {
  return (
    <div
      ref={ref}
      className={clsx(sharedStyles, interactive ? interactiveStyles : nonInteractiveStyles, className)}
      {...props}
    >
      {colorBar && (
        <div
          className="w-1! h-3! block rounded-full"
          style={{
            backgroundColor:
              colorBar in colorBarValues ? colorBarValues[colorBar as keyof typeof colorBarValues] : colorBar,
          }}
        />
      )}
      {IconLeft && (
        <span className={clsx(`shrink-0 [&_svg]:h-4 [&_svg]:w-4`, iconStatuses[iconStatus])}>{IconLeft}</span>
      )}
      <span className="text-ellipsis text-nowrap overflow-hidden flex-1">{children}</span>
      {IconRight && (
        <span className={clsx(`shrink-0 [&_svg]:h-4 [&_svg]:w-4`, iconStatuses[iconStatus])}>{IconRight}</span>
      )}
    </div>
  );
});
