import { ButtonHTMLAttributes, forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import ButtonContent, { BaseButtonProps, sharedStyles, variantStyles, sizeStyles } from './ButtonContent';
import clsx from 'clsx';
import { ArrowPathIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

export type ButtonProps = {
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  successMessage?: string;
  errorMessage?: string;
} & ButtonHTMLAttributes<HTMLButtonElement> &
  Omit<BaseButtonProps, 'variant'>;

export { variantStyles };

type ButtonStates = 'default' | 'loading' | 'error' | 'success';

const BUTTON_TIMEOUT_LENGTH = 5000;

const IconLeftOverrideSwitch = ({ IconLeft, state }: { IconLeft: ReactNode; state: ButtonStates }) => {
  if (state === 'default') {
    return IconLeft;
  } else if (state === 'loading') {
    return <ArrowPathIcon className="animate-spin" />;
  } else if (state === 'error') {
    return <ExclamationCircleIcon />;
  } else if (state === 'success') {
    return <CheckCircleIcon />;
  }
  return null;
};

const variantMap: Record<ButtonStates, keyof typeof variantStyles> = {
  default: 'primary',
  loading: 'primary',
  error: 'negative-primary',
  success: 'positive-primary',
};

export default forwardRef<HTMLButtonElement, ButtonProps>(function ReactiveButton(
  {
    size = 'md',
    className,
    children,
    IconLeft,
    IconRight,
    isLoading,
    isSuccess,
    isError,
    successMessage,
    errorMessage,
    disabled,
    ...props
  }: ButtonProps,
  ref,
) {
  const stateTimeout = useRef<NodeJS.Timeout | null>(null);
  const [state, setState] = useState<ButtonStates>('default');

  useEffect(() => {
    if (isLoading) {
      setState('loading');
    }
    if (stateTimeout.current) {
      clearTimeout(stateTimeout.current);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      setState('success');
      stateTimeout.current = setTimeout(() => {
        setState('default');
      }, BUTTON_TIMEOUT_LENGTH);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setState('error');
      stateTimeout.current = setTimeout(() => {
        setState('default');
      }, BUTTON_TIMEOUT_LENGTH);
    }
  }, [isError]);

  const IconLeftOverride =
    !IconLeft && state === 'default' ? null : <IconLeftOverrideSwitch IconLeft={IconLeft} state={state} />;

  const hasSuccessAndErrorMessage = successMessage !== undefined && errorMessage !== undefined;
  if (successMessage === undefined && errorMessage !== undefined) {
    throw new Error('successMessage must be defined if errorMessage is defined');
  } else if (successMessage !== undefined && errorMessage === undefined) {
    throw new Error('errorMessage must be defined if successMessage is defined');
  }

  return (
    <button
      ref={ref}
      {...props}
      className={clsx(sharedStyles, variantStyles[variantMap[state]], sizeStyles[size], className)}
      disabled={disabled || isLoading}
      // @ts-expect-error
      style={{ interpolateSize: 'allow-sizes' }}
    >
      <ButtonContent IconLeft={IconLeftOverride} IconRight={IconRight}>
        <div className="overflow-hidden grid">
          <div
            className={clsx(
              'transition-all translate-y-0 row-start-1 row-end-1 col-start-1 col-end-1',
              hasSuccessAndErrorMessage && ['success', 'error'].includes(state) && 'translate-y-full!',
            )}
          >
            {children}
          </div>
          {hasSuccessAndErrorMessage && (
            <>
              <div
                className={clsx(
                  'transition-all -translate-y-full row-start-1 row-end-1 col-start-1 col-end-1 text-white!',
                  state === 'success' && 'translate-y-0',
                )}
              >
                {successMessage}
              </div>
              <div
                className={clsx(
                  'transition-all -translate-y-full row-start-1 row-end-1 col-start-1 col-end-1 text-white!',
                  state === 'error' && 'translate-y-0',
                )}
              >
                {errorMessage}
              </div>
            </>
          )}
        </div>
      </ButtonContent>
    </button>
  );
});
