import * as HeadlessUI from '@headlessui/react';
import Button, { ButtonProps } from '../Button';
import Link, { LinkProps } from 'next/link';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';

function Menu(props: HeadlessUI.MenuProps) {
  // as="div" included or it won't actually render anything for the outer menu. Can't imagine we would ever want to /not/ render it.
  return <HeadlessUI.Menu as="div" {...props} />;
}

function MenuButton(props: HeadlessUI.MenuButtonProps & ButtonProps) {
  return <HeadlessUI.MenuButton as={Button} {...props} />;
}

function MenuItems({ className, ...props }: HeadlessUI.MenuItemsProps) {
  return (
    <HeadlessUI.MenuItems
      transition
      className={clsx(
        `
        absolute right-0 z-10 mt-2 p-2 w-56 origin-top-right rounded-lg
        shadow-medium-down bg-general-neutral-white ring-1 ring-general-neutral-light
        focus:outline-hidden
        transition data-closed:scale-95 data-closed:transform data-closed:opacity-0 data-enter:duration-100 data-leave:duration-75 data-enter:ease-out data-leave:ease-in
      `,
        className,
      )}
      {...props}
    />
  );
}

function MenuItem(props: HeadlessUI.MenuItemProps) {
  return <HeadlessUI.MenuItem {...props} />;
}

const sharedItemStyles = `flex gap-x-2 items-center p-2 w-full rounded-lg text-body-md [&>svg]:w-4 [&>svg]:h-4 z-50`;

const itemVariantStyles = {
  default: `
    text-action-content-secondary-enabled
    hover:text-action-content-secondary-hover hover:bg-action-fill-secondary-hover
    data-[focus]:text-action-content-secondary-active data-[focus]:bg-action-fill-secondary-active data-[focus]:[&>svg]:text-content-brand
    disabled:text-action-content-secondary-disabled data-[disabled]:text-action-content-secondary-disabled
  `,
  active: `
    text-white! bg-action-fill-primary-enabled
    hover:text-action-content-primary-hover hover:bg-action-fill-primary-hover
    data-[focus]:text-action-content-primary-active data-[focus]:bg-action-fill-primary-active data-[focus]:[&>svg]:text-action-content-primary-enabled
    disabled:text-action-content-primary-disabled data-[disabled]:text-action-content-primary-disabled
  `,
  negative: `
    text-action-content-negative-secondary-enabled
    hover:text-action-content-negative-secondary-hover hover:bg-action-fill-negative-secondary-hover
    data-[focus]:text-action-content-secondary-active data-[focus]:bg-general-negative data-[focus]:[&>svg]:text-action-content-negative-secondary-hover
    disabled:text-action-content-negative-secondary-disabled data-[disabled]:text-action-content-negative-secondary-disabled
  `,
};
type MenuItemContentProps = {
  children: ReactNode;
  IconRight?: ReactNode;
  IconLeft?: ReactNode;
  className?: string;
  variant?: keyof typeof itemVariantStyles;
};

function MenuLink({
  IconLeft,
  IconRight,
  children,
  className,
  variant = 'default',
  ...props
}: LinkProps & MenuItemContentProps) {
  return (
    <Link className={clsx(sharedItemStyles, itemVariantStyles[variant], className)} {...props}>
      {IconLeft && <span className="flex-initial">{IconLeft}</span>}
      {children}
      {IconRight && <span className="flex-initial">{IconRight}</span>}
    </Link>
  );
}

function MenuItemButton({
  IconLeft,
  IconRight,
  children,
  className,
  variant = 'default',
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & MenuItemContentProps) {
  return (
    <button className={clsx(sharedItemStyles, itemVariantStyles[variant], className)} {...props}>
      {IconLeft && <span className="flex-initial [&_svg]:w-4 [&_svg]:h-4">{IconLeft}</span>}
      {children}
      {IconRight && <span className="flex-initial [&_svg]:w-4 [&_svg]:h-4">{IconRight}</span>}
    </button>
  );
}

function MenuSection(props: HeadlessUI.MenuSectionProps) {
  return <HeadlessUI.MenuSection {...props} />;
}

function MenuHeading(props: HeadlessUI.MenuHeadingProps) {
  return <HeadlessUI.MenuHeading {...props} />;
}

function MenuSeparator(props: HeadlessUI.MenuSeparatorProps) {
  return <HeadlessUI.MenuSeparator className="my-1 h-px bg-general-neutral-light" {...props} />;
}

export default {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  MenuLink,
  MenuItemButton,
  MenuSection,
  MenuHeading,
  MenuSeparator,
};
