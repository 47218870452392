import * as ReactSelect from 'react-select';
import { XCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

type Size = 'md' | 'lg';

const styles = (size: Size): ReactSelect.ClassNamesConfig => ({
  container: () => `z-auto`,
  menu: () => `z-50`,
  control: state =>
    clsx(
      `rounded-lg! pl-1 font-body-lg!`,
      size === 'md' && `min-h-8! text-body-md!`,
      size === 'lg' && `min-h-10! text-body-lg!`,
      state.isDisabled && `text-gray-200 bg-general-neutral-lighter`,
      !state.isDisabled && `text-white`,
      state.isFocused && `border border-content-brand hover:border-content-brand`,
      state.isFocused && state.isDisabled
        ? // These two borders are the exact same colour
          `border border-interaction-outline-disabled! hover:border-interaction-outline-hover`
        : `border border-interaction-outline-enabled! hover:border-interaction-outline-hover`,
    ),
  valueContainer: () => (size === 'md' ? `py-0!` : ''),
  dropdownIndicator: () => (size === 'md' ? `p-0.5!` : ''),
  input: () => `[&>input]:focus:ring-transparent`,
  placeholder: () => `whitespace-nowrap overflow-hidden text-ellipsis text-content-tertiary!`,
  menuList: () => `py-0!`,
  option: () => `text-body-lg!`,
  multiValue: () =>
    `border rounded-md! text-button-md! font-button-md! gap-x-1! px-1! py-0.5! [&_svg]:w-4!
    shadow-light-down! bg-action-fill-secondary-enabled! border-action-outline-secondary-enabled! text-action-content-secondary-enabled!
  `,
  multiValueLabel: () => `p-0! text-action-content-secondary-enabled!`,
  multiValueRemove: () =>
    `p-0! hover:bg-action-fill-negative-secondary-hover! hover:text-action-content-negative-secondary-hover! cursor-pointer!`,
  singleValue: () => `text-content-primary!`,
});

export default function SelectInput<
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>({ size = 'lg', ...props }: ReactSelect.Props<Option, IsMulti, Group> & { size?: Size }) {
  return (
    <ReactSelect.default
      {...props}
      classNames={styles(size) as unknown as ReactSelect.ClassNamesConfig<Option, IsMulti, Group>}
      components={{
        MultiValueRemove: props => (
          <ReactSelect.components.MultiValueRemove {...props}>
            <XCircleIcon />
          </ReactSelect.components.MultiValueRemove>
        ),
        ...props.components,
      }}
    />
  );
}

export * from 'react-select';
